<template>
    <div :style="{ padding: 0, alignItems: 'center', justifyContent: 'center' }">
        <div :style="{display: 'flex', flexWrap: 'wrap', gap: '2vw'}" class="over">
          <div v-for="( item, index ) in items" :key=index :style="{maxWidth: '400', flex: '7 300px', padding: '10px', margin: '20px 0', textAlign: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column'}">
                    <!-- <img :src="tips1" alt="tip1" :style="{width: '20vw', maxWidth: '146px', height: '20vw', maxHeight: '146px'}"> -->
                    <div :style="{background: '#fff', width: '154px', height: '154px', alignItems: 'center', justifyContent: 'center', display: 'flex', marginBottom: '30px', borderRadius: '50%'}">
                      <img :src="item.image" />
                    </div>
                    <span :style="{fontWeight: 700, color: '#000', fontSize: '18px', marginBottom: '20px'}">{{ item.title }}</span>
                    <span :style="{fontWeight: 400, color: '#000', fontSize: '14px'}">
                        {{ item.sub }}
                    </span>
            </div>
        </div>
    </div>
</template>
<script>

export default {
  name: 'Info',
  components: {
  },
  methods: {
  },
  data () {
    return {
      items: [
        {
          image: require('../../assets/btc.png'),
          title: 'TIP 1',
          sub: 'TIPS 플랫폼에서 자유롭게 글과 이미지를 올리세요. 당신이 게시판에 글을 적고 정보와 이야기를 함께하면 TIPS 코인이 항상 무료 배포되어집니다.'
        },
        {
          image: require('../../assets/btc.png'),
          title: 'TIP 1',
          sub: 'TIPS 플랫폼에서 자유롭게 글과 이미지를 올리세요. 당신이 게시판에 글을 적고 정보와 이야기를 함께하면 TIPS 코인이 항상 무료 배포되어집니다.'
        },
        {
          image: require('../../assets/btc.png'),
          title: 'TIP 1',
          sub: 'TIPS 플랫폼에서 자유롭게 글과 이미지를 올리세요. 당신이 게시판에 글을 적고 정보와 이야기를 함께하면 TIPS 코인이 항상 무료 배포되어집니다.'
        }
      ]
    }
  }

}
</script>
