<template>
    <div>
        <div :style="{ display: 'flex', alignItems: 'center', justifyContent: 'center' }">
            <div
                :style="{ display: 'inline-block', width: 'auto', padding: '8px 48px', background: 'rgba(18, 49, 158, 0.08)', border: '1px solid #032D98', borderRadius: '40px' }">
                <span :style="{ fontSize: '16px', color: '##032D98', fontWeight: 600 }">
                    TIPS COIN Whitepaper
                </span>
            </div>
        </div>
        <div :style="{ width: '100%', marginTop: '30px', display: 'flex' }">
            <img :src=mainImg alt="TIPS" :style="{ width: '50%' }" />
            <div class="downBox">
                <a target='_blank' href="/whitepaper/TIPS_whitepaper_en.pdf" class="ko">한글백서 다운로드</a>
                <a target='_blank' href="/whitepaper/TIPS_whitepaper_en.pdf" class="en">English Version Download</a>
                <a target='_blank' href="/whitepaper/TIPS_whitepaper_en.pdf" class="ch">中文版本下载</a>
            </div>
        </div>
    </div>
</template>

<script>

export default {
  name: 'Whitepaper',
  mounted () {
  },
  data () {
    return {
      mainImg: require('../../assets/whitepaper.png'),
      koPaper: require('../../assets/whitepaper.png'),
      enPaper: require('../../assets/whitepaper.png'),
      chPaper: require('../../assets/whitepaper.png')
    }
  }

}
</script>
<style>
.downBox {
    padding-left: 80px;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ko {
    font-size: 20px;
    color: #000;
    cursor: pointer;
    text-decoration: none;
}

.en {
    font-size: 20px;
    color: #000;
    cursor: pointer;
    text-decoration: none;
}

.ch {
    font-size: 20px;
    color: #000;
    cursor: pointer;
    text-decoration: none;
}

@media (max-width: 480px) {
    .downBox {
        padding-left: 20px;
    }
    .ko {
        font-size: 13px;
    }

    .en {
        font-size: 13px;
    }

    .ch {
        font-size: 13px;
    }
}</style>
