var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('KilpQRModal',{attrs:{"value":_vm.klipModalShow,"qr":_vm.klipQRState},on:{"statKilpModal":_vm.setKlipModal}}),_c('SelectWalletModal',{attrs:{"value":_vm.selectModalShow,"coinType":_vm.coinType},on:{"statKilpModal":_vm.setKlipModal,"setWalletModal":_vm.setWalletModal,"action":_vm.action,"setWallet":_vm.setWallet}}),_c('PurchaseModal',{attrs:{"value":_vm.modalShow,"modalType":_vm.type,"wallet":_vm.wallet,"coinType":_vm.coinType,"fromToken":_vm.selectedItem.from,"ratio":_vm.selectedItem.ratio,"toToken":_vm.selectedItem.symbol,"balance":_vm.walletState.amount},on:{"statAmountChange":_vm.setModal,"statKilpModal":_vm.setKlipModal}}),_c('div',[_c('div',[_c('div',{style:({ display: 'flex', alignItems: 'center', justifyContent: 'center' })},[_c('div',{style:({ display: 'inline-block', width: 'auto', padding: '8px 48px', background: 'rgba(18, 49, 158, 0.08)', border: '1px solid #032D98', borderRadius: '40px' })},[_c('span',{style:({ fontSize: '16px', color: '##032D98', fontWeight: 600 })},[_vm._v(" WEB 3.0 COIN Exchange ")])])]),_c('div',{style:({ textAlign: 'center', paddingTop: '20px' })},[_c('p',[_c('span',{style:({ fontSize: '30px', color: '#000', fontWeight: 700 })},[_vm._v(" TIPS가 제안하는 코인으로 지금 교환하세요. ")])]),_c('p',[_c('span',{style:({ fontSize: '16px', color: '#000', fontWeight: 400 })},[_vm._v(" 회원가입 없이 지갑 연결만으로 교환이 가능합니다. ")])])]),_c('div',{style:({ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap', gap: '2vw' })},_vm._l((_vm.tokens),function(token){return _c('div',{key:token.name},[_c('iq-card',{style:({
              width: '380px',
              alignItems: 'center',
              justifyContent: 'center',
              background: '#FFF',
              padding: '20px',
              borderRadius: '4px',
              marginBottom: '0px',
              boxShadow: '2px 2px 8px rgba(0, 0, 0, 0.15)'})},[_c('div',{attrs:{"stlye":{width: '100%'}}},[_c('div',{style:({display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', width: '75%'})},[_c('img',{style:({ width: 'auto', height: '60px' }),attrs:{"src":token.logo}}),_c('div',{style:({display: 'flex', alignItems: 'start', flexDirection: 'column'})},[_c('span',{style:({ fontSize: '20px', fontWeight: 700, color: '#000'})},[_vm._v(_vm._s(token.symbol))]),_c('span',{style:({ fontSize: '14px', fontWeight: 500, color: '#000'})},[_vm._v(_vm._s(token.explan))]),_c('span',{style:({ fontSize: '14px', fontWeight: 500, color: '#000'})},[_vm._v(_vm._s(token.stockExchange))])])]),_c('div',{style:({display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '11px', margin: '15px 0'})},[_c('span',{style:({ fontSize: '24px', fontWeight: 700, color: '#000'})},[_vm._v("1 "+_vm._s(token.symbol))]),_c('div',{style:({display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#F2F2F2', width: '31px', height: '31px', borderRadius: '50%'})},[_c('span',{style:({ fontSize: '24px', fontWeight: 700, color: '#000'})},[_vm._v("=")])]),_c('span',{style:({ fontSize: '24px', fontWeight: 700, color: '#000'})},[_vm._v(_vm._s(token.ratio)+" "+_vm._s(token.from))])]),_c('div',{style:({display: 'flex', gap: '10px'})},[_c('div',{style:({
                        cursor: 'pointer',
                        flex: 1,
                        maxWidth: '300px',
                        height: '48px',
                        background: '#032D98',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        border: '1px solid #032D98',
                        borderRadius: '4px'
                      }),on:{"click":function($event){return _vm.setWalletModal(true, token, 'buy')}}},[_c('span',{style:({ fontSize: '16px', fontWeight: 600, color: '#fff'})},[_vm._v("교환하기")])]),_c('div',{style:({
                        cursor: 'pointer',
                        width: '48px',
                        height: '48px',
                        background: '#fff',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        border: '1px solid #032D98',
                        borderRadius: '4px'
                      }),on:{"click":function($event){return _vm.copy(token.symbol, token.contract)}}},[_c('span',{style:({ fontSize: '16px', fontWeight: 600, color: '#032D98'})},[_c('i',{staticClass:"ri-file-copy-line"})])])])])])],1)}),0)]),_c('div',{style:({ textAlign: 'center', paddingTop: '20px' }),attrs:{"id":"bottom"}},[_c('span',{style:({ fontSize: '12px', color: '#959595', fontWeight: 400 })},[_vm._v(" Metamask / Kaikas / Klip / TronLink"),_c('br'),_vm._v("Wallet available ")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }