<template>
  <div>
    <KilpQRModal
    @statKilpModal="setKlipModal"
    :value="klipModalShow"
    :qr="klipQRState"
    />
    <SelectWalletModal
     @statKilpModal="setKlipModal"
     @setWalletModal="setWalletModal"
     @action="action"
     @setWallet="setWallet"
     :value="selectModalShow"
     :coinType="coinType" />
    <PurchaseModal
     @statAmountChange="setModal"
     @statKilpModal="setKlipModal"
     :value="modalShow"
     :modalType="type"
     :wallet="wallet"
     :coinType="coinType"
     :fromToken="selectedItem.from"
     :ratio="selectedItem.ratio"
     :toToken="selectedItem.symbol"
     :balance="walletState.amount" />
     <!-- <div class="coinCard" > -->
     <div>
      <div>
        <div :style="{ display: 'flex', alignItems: 'center', justifyContent: 'center' }">
          <div
            :style="{ display: 'inline-block', width: 'auto', padding: '8px 48px', background: 'rgba(18, 49, 158, 0.08)', border: '1px solid #032D98', borderRadius: '40px' }">
            <span :style="{ fontSize: '16px', color: '##032D98', fontWeight: 600 }">
              WEB 3.0 COIN Exchange
            </span>
          </div>
        </div>
        <div :style="{ textAlign: 'center', paddingTop: '20px' }">
          <p>
            <span :style="{ fontSize: '30px', color: '#000', fontWeight: 700 }">
              TIPS가 제안하는 코인으로 지금 교환하세요.
            </span>
          </p>
          <p>
            <span :style="{ fontSize: '16px', color: '#000', fontWeight: 400 }">
              회원가입 없이 지갑 연결만으로 교환이 가능합니다.
            </span>
          </p>
        </div>
        <div :style="{ display: 'flex', width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap', gap: '2vw' }">
          <div v-for="(token) in tokens" :key="token.name">
              <iq-card :style="{
                width: '380px',
                alignItems: 'center',
                justifyContent: 'center',
                background: '#FFF',
                padding: '20px',
                borderRadius: '4px',
                marginBottom: '0px',
                boxShadow: '2px 2px 8px rgba(0, 0, 0, 0.15)'}">
                <div :stlye="{width: '100%'}">
                  <div :style="{display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', width: '75%'}">
                    <img :src="token.logo" :style="{ width: 'auto', height: '60px' }" />
                    <div :style="{display: 'flex', alignItems: 'start', flexDirection: 'column'}">
                      <span :style="{ fontSize: '20px', fontWeight: 700, color: '#000'}">{{ token.symbol }}</span>
                      <span :style="{ fontSize: '14px', fontWeight: 500, color: '#000'}">{{ token.explan }}</span>
                      <span :style="{ fontSize: '14px', fontWeight: 500, color: '#000'}">{{ token.stockExchange }}</span>
                    </div>
                  </div>
                  <div :style="{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '11px', margin: '15px 0'}">
                      <span :style="{ fontSize: '24px', fontWeight: 700, color: '#000'}">1 {{ token.symbol }}</span>
                      <div :style="{display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#F2F2F2', width: '31px', height: '31px', borderRadius: '50%'}">
                      <span :style="{ fontSize: '24px', fontWeight: 700, color: '#000'}">=</span>
                      </div>
                      <span :style="{ fontSize: '24px', fontWeight: 700, color: '#000'}">{{ token.ratio }} {{ token.from }}</span>
                  </div>
                  <div :style="{display: 'flex', gap: '10px'}">
                      <div
                        @click="setWalletModal(true, token, 'buy')"
                        :style="{
                          cursor: 'pointer',
                          flex: 1,
                          maxWidth: '300px',
                          height: '48px',
                          background: '#032D98',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          border: '1px solid #032D98',
                          borderRadius: '4px'
                        }">
                        <span :style="{ fontSize: '16px', fontWeight: 600, color: '#fff'}">교환하기</span>
                      </div>
                      <div
                        @click="copy(token.symbol, token.contract)"
                        :style="{
                          cursor: 'pointer',
                          width: '48px',
                          height: '48px',
                          background: '#fff',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          border: '1px solid #032D98',
                          borderRadius: '4px'
                        }">
                        <span :style="{ fontSize: '16px', fontWeight: 600, color: '#032D98'}"><i class="ri-file-copy-line"></i></span>
                      </div>
                  </div>
                </div>
              </iq-card>
            </div>
        </div>
        </div>
        <div id="bottom" :style="{ textAlign: 'center', paddingTop: '20px' }">
            <span :style="{ fontSize: '12px', color: '#959595', fontWeight: 400 }">
              Metamask / Kaikas / Klip / TronLink<br />Wallet available
            </span>
        </div>
      </div>
    </div>
</template>
<script>
import SelectWalletModal from '../../components/core/modal/selectWalletModal.vue'
import PurchaseModal from '../../components/core/modal/purchaseModal.vue'
import KilpQRModal from '../../components/core/modal/kilpQRModal.vue'
import { mapActions, mapGetters } from 'vuex'
import MMSDK from '../../services/wallet/metamask'
import kaikasSDK from '../../services/wallet/kaikas'
import kilpSDK from '../../services/wallet/klip'
import tronSDK from '../../services/wallet/tronLink'
import walletConnet from '../../services/wallet/walletConnet'
// import trustSDK from '../../services/wallet/trust'

export default {
  name: 'Coin',
  computed: {
    ...mapGetters({
      walletState: 'WalletConnect/walletState',
      klipQRState: 'WalletList/klipQRState',
      userState: 'Auth/userState'
    })
  },
  components: {
    SelectWalletModal,
    PurchaseModal,
    KilpQRModal
  },
  methods: {
    copy (symbol, temp) {
      if (temp === 'ready') {
        alert('준비중 입니다.')
        return
      }
      this.$copyText(temp)
      alert(`${symbol} 컨트렉트 주소를 복사했습니다.`)
    },
    async metamask () {
      const connect = await MMSDK.connect(this.setAddress, this.setAmount, this.updateWalletStaking)
      if (connect) {
        this.setModal()
      }
    },
    async kaikas () {
      const connect = kaikasSDK.connect(this.$isMobile(), this.setModal, this.setAddress, this.setAmount)
      if (connect) {
        this.setModal()
      }
    },
    async klip () {
      await kilpSDK.connect('next', this.$isMobile(), this.setKlipQR, this.setKlipModal, this.setWalletModal, this.setModal, this.setAddress, this.setAmount)
    },
    async tronLink () {
      const connect = await tronSDK.connect(this.setAddress, this.setAmount, this.updateWalletStaking)
      if (connect) {
        this.setModal()
      }
    },
    async trust () {
      const connect = await walletConnet.connect(this.setAddress, this.setAmount, this.updateWalletStaking)
      if (connect) {
        this.setModal()
      }
    },

    ...mapActions({
      updateWalletStaking: 'Staking/updateWalletStaking',
      setId: 'WalletConnect/setId',
      setAddress: 'WalletConnect/setAddress',
      setKlipQR: 'WalletList/setKlipQR',
      setAmount: 'WalletConnect/setAmount',
      setProvider: 'WalletConnect/setProvider',
      getWalletList: 'WalletList/getWalletList',
      pushWallet: 'WalletList/pushWallet'
    }),
    // 모달 제어
    setWalletModal (type, item, actionType) {
      if (type) {
        if (actionType === 'charging') {
          if (this.userState.user_id === 0) {
            alert('로그인이 필요한 서비스입니다.')
            return
          }
        }
        this.coinType = item.from
        this.selectedItem = item ?? this.selectedItem
        this.type = actionType ?? this.type
      }
      this.selectModalShow = type
    },
    setWallet (temp) {
      this.wallet = temp
    },
    // 모달 제어
    async setModal (type) {
      if (type) {
        this.modalShow = type
        return
      }
      this.modalShow = !this.modalShow
    },
    // 모달 제어
    async setKlipModal (type) {
      // if (type) {
      this.klipModalShow = type
      // return
      // }
      // this.klipModalShow = !this.klipModalShow
    },

    async action () {
      switch (this.wallet) {
        case 'Metamask':
          this.selectedItem.doMetaMask()
          break
        case 'Kaikas':
          this.selectedItem.doKaikas()
          break
        case 'Klip':
          this.selectedItem.doKlip()
          break
        case 'TronLink':
          this.selectedItem.doTronLink()
          break
        case 'Trust':
          this.selectedItem.doTrust()
          break
      }
    }
  },
  data () {
    return {
      coinType: null,
      // isMobile: this.$isMobile(),
      tokens: [
        {
          logo: require('../../assets/ktips.png'),
          from: 'KLAY',
          symbol: 'TIPS',
          explan: 'Korea Tips Coin',
          ratio: '0.5',
          network: 'Klaytn',
          contract: '0x981AeB68B7A9d1B3d9341636D0f45660995C6Af5',
          stockExchange: '준비중',
          doMetaMask: () => alert('준비중입니다.'),
          doKaikas: () => alert('준비중입니다.'),
          doKlip: () => alert('준비중입니다.')
          // doMetaMask: () => this.metamask(),
          // doKaikas: () => this.kaikas(),
          // doKlip: () => this.klip()
        },
        {
          logo: require('../../assets/kdia.png'),
          from: 'TRX',
          symbol: 'KDIA',
          explan: 'Global Diamond Coin',
          ratio: '0.01',
          network: 'TRX',
          contract: 'TP2jCch77RFra7PRAz8QrMW1sCyieAL6Uh',
          stockExchange: 'Digifinex',
          doTronLink: () => this.tronLink(),
          doTrust: () => this.trust()
        },
        {
          logo: require('../../assets/ktips.png'),
          from: 'KLAY',
          symbol: 'TIPS2',
          explan: 'Korea Tips Coin',
          ratio: '0.5',
          network: 'Klaytn',
          contract: 'ready',
          stockExchange: '준비중',
          doMetaMask: () => alert('준비중입니다.'),
          doKaikas: () => alert('준비중입니다.'),
          doKlip: () => alert('준비중입니다.')
        }
      ],
      selectModalShow: false,
      modalShow: false,
      klipModalShow: false,
      selectedItem: {},
      type: '',
      wallet: ''
    }
  }
}
</script>
<style>

</style>
