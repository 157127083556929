<template>
  <div :style="{ paddingBottom: '30px', alignItems: 'center', justifyContent: 'center' }">
    <span :style="{ fontWeight: 700, fontSize: '24px', color: '#000' }">FAQs</span>
    <div :style="{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', marginTop: '15px' }">
      <div :style="{ width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column' }">
        <div :style="{ width: '100%', backgroundColor: '#F2F2F2', height: '2px', margin: '5px 0', padding: 0 }" />
        <div v-for="(item, index) in list" :key="index">
          <div :style="{ width: '100%', display: 'flex', flexDirection: 'column' }">
            <div :style="{ margin: '5px 0', padding: '0 10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }">
              <span :style="{ fontWeight: 400, fontSize: '16px' }">
                {{ item.question }}
              </span>
              <div v-if="!item.open" @click="setOpen(index)"
                :style="{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#F2F2F2', width: '31px', height: '31px', borderRadius: '50%' }">
                <span :style="{ fontSize: '24px', fontWeight: 700, color: '#032D98' }">+</span>
              </div>
              <div v-else @click="setOpen(index)"
                :style="{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#F2F2F2', width: '31px', height: '31px', borderRadius: '50%' }">
                <span :style="{ fontSize: '24px', fontWeight: 700, color: '#032D98' }">-</span>
              </div>
            </div>
            <div v-show="item.open" :style="{ border: '1px solid #000', background: '#fff', padding: '10px', marginTop: '10px' }">
              <span :style="{}">
                {{ item.asnwer }}
              </span>
            </div>
            <div :style="{ width: '100%', backgroundColor: '#F2F2F2', height: '2px', margin: '5px 0', padding: 0 }" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import faq from '../../services/faq'

export default {
  name: 'Faq',
  components: {
  },
  mounted () {
    this.getList()
  },
  methods: {
    setOpen (index) {
      this.list[index].open = !this.list[index].open
    },
    getList () {
      faq.getList().then(res => {
        const items = res.data.list
        for (let i = 0; i < items.length; i++) {
          items[i].open = false
        }
        this.list = items
      })
    }
  },
  data () {
    return {
      list: [
        {
          open: false,
          title: 'TIPS 코인은 뭔가요?',
          answer: 'TIPS 플랫폼에서 자유롭게 글과 이미지를 올리세요. 당신이 게시판에 글을 적고 정보와 이야기를 함께하면 TIPS 코인이 항상 무료 배포되어집니다.'
        },
        {
          open: false,
          title: 'TIPS 코인은 뭔가요?',
          answer: 'TIPS 플랫폼에서 자유롭게 글과 이미지를 올리세요. 당신이 게시판에 글을 적고 정보와 이야기를 함께하면 TIPS 코인이 항상 무료 배포되어집니다.'
        },
        {
          open: false,
          title: 'TIPS 코인은 뭔가요?',
          answer: 'TIPS 플랫폼에서 자유롭게 글과 이미지를 올리세요. 당신이 게시판에 글을 적고 정보와 이야기를 함께하면 TIPS 코인이 항상 무료 배포되어집니다.'
        },
        {
          open: false,
          title: 'TIPS 코인은 뭔가요?',
          answer: 'TIPS 플랫폼에서 자유롭게 글과 이미지를 올리세요. 당신이 게시판에 글을 적고 정보와 이야기를 함께하면 TIPS 코인이 항상 무료 배포되어집니다.'
        }
      ]
    }
  }

}
</script>
