import client from './index'

export default {
  /**
   * faq 전체 리스트
   */
  async getList () {
    try {
      return client.json.get('/faqs')
    } catch (err) {
      // console.log(err)
    }
  }
}
