<template>
    <div class="level-box" :style="{ flexDirection: 'column', padding: 0, alignItems: 'start', justifyContent: 'center' }">
      <div :style="{display: 'flex', width: '100%', padding: '0 5%', justifyContent: 'space-between'}">
        <div :style="{display: 'flex', justifyContent: 'center', alignItems: 'center'}">
          <span :style="{ padding: '10px 5px', fontSize: '1rem', fontWeight: '600', color: '#000' }">레벨 정보</span>
          <div>
      <b-button id="tooltip-button-interactive" variant="none">
        <h5>
          <i class="ri-question-line"/>
        </h5>
      </b-button>
      <b-tooltip target="tooltip-button-interactive">마이페이지에서 신청하면 레벨업이 가능해요!</b-tooltip>
    </div>
        </div>
        <span :style="{ padding: '10px 5px', fontSize: '0.8rem', fontWeight: '500', color: '#000' }">(1일, 1회 기준)</span>
      </div>
        <div :style="{ border: '1px solid #acacac', width: '100%', padding: '10px' }">
            <div
            class="level"
                :style="{ display: 'flex', width: '100%', height: 'auto' }">
                <div class="level-box-gap">
                    <div v-for="(item, index) in items1" :key="index" :style="{  width: '100%' }">
                        <div :style="{ display: 'flex', flexDirection: 'row' }">
                          <div
                                :style="{ width: '20%', height: '3vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }">
                                <span >Lv.{{ item.level }}</span>
                              </div>
                              <div :style="{width: '100%', alignItems: 'center', display: 'flex'}">
                                <div :style="{ display: 'block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', flex: 1 }">{{ item.posting_max.toLocaleString() }} 포스팅, {{ item.mining_value.toLocaleString() }} TIPS</div>
                              </div>
                        </div>
                    </div>
                </div>
                <div class="level-box-gap">
                    <div v-for="(item, index) in items2" :key="index" :style="{ width: '100%' }">
                      <div :style="{ display: 'flex', flexDirection: 'row' }">
                          <div
                                :style="{ width: '20%', height: '3vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }">
                                <span >Lv.{{ item.level }}</span>
                            </div>
                            <div :style="{width: '100%', alignItems: 'center', display: 'flex'}">
                              <div :style="{ display: 'block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', flex: 1 }">{{ item.posting_max.toLocaleString() }} 포스팅, {{ item.mining_value.toLocaleString() }} TIPS</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </template>
<script>

import { mapGetters } from 'vuex'

export default {
  name: 'Level',
  components: {
  },
  mounted () {
    this.getLevel()
  },
  methods: {
    getLevel () {
      this.items1 = this.levelState.slice(0, 5)
      this.items2 = this.levelState.slice(5, 10)
    }
  },
  computed: {
    ...mapGetters({
      levelState: 'Setting/levelState'
    })
  },
  data () {
    return {
      items1: [],
      items2: []
      // image: require('../../assets/level.png')
    }
  }
}
</script>
  <style>
  .level {
        flex-direction: row;
    }
  .level-box {
    display: flex;
    width: 48%;
  }
  .level-box-gap {
        width: 50%;
    }
  @media (max-width: 480px) {
    .level {
        flex-direction: column;
        gap: 10px;
    }
    .level-box {
    display: flex;
    width: 100%;
    }
    .level-box-gap {
        width: 100%;
        gap: 10px;
        display: flex;
        flex-direction: column;
    }
  }
  </style>
