import Web3 from 'web3'
import WalletConnectProvider from '@walletconnect/web3-provider'
import Web3Modal from 'web3modal'
// import { WalletConnectChainID } from '@tronweb3/walletconnect-tron'
import { kdiaCrowdSale } from '../../abi/kdiaCrowdSale'
// import Decimal from 'decimal.js'
// import wallet from '../wallet'
// import { abi } from '../../abi/abi'
// const network = WalletConnectChainID.Mainnet
const network = 137 // 폴리곤 137
// const tokenAddress = '0xfc3f4D3437D507560B7A243bB5ad1C745530E182'
const crowdsaleAddress = '0xdCb7004Ba85A020B7e3099509009E8E7e6480A04'

/**
   * 지갑 연결
   * @param {*} setProvider action setProvider
   * @param {*} setAddress action setAddress
   * @param {*} setAmount action setAmount
   */
async function connect (setProvider, setAddress, setAmount) {
  try {
    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          rpc: {
            137: 'https://polygon-rpc.com'
          },
          chainId: [network]
        }
      }
    }

    const web3Modal = new Web3Modal({
      network: 'mainnet', // optional
      cacheProvider: true, // optional
      providerOptions // required
    })

    const provider = await web3Modal.connectTo('walletconnect')
    // setProvider(provider)
    // myProvider = provider

    await web3Modal.toggleModal()

    // Subscribe to chainId change
    provider.on('chainChanged', async (chainId) => {
      window.location.reload()
    })

    provider.on('disconnect', (code, reason) => {
      window.location.reload()
      // setAddress('')
      // myAddress = ''
    })

    const newWeb3 = new Web3(provider)

    const chainId = await newWeb3.eth.getChainId()
    if (chainId === network) {
      const accounts = await newWeb3.eth.getAccounts()
      // console.log(accounts)

      const amount = await newWeb3.eth.getBalance(accounts[0])
      setAddress(accounts[0])
      setAmount(newWeb3.utils.fromWei(amount))
    } else {
      alert('PolygonTest needs Polygon MainNet.\nPlease choose Polygon.')
      await provider.disconnect()
    }
  } catch (err) {
    // console.log(err)
  }
}

/**
   * 토큰 구매(지갑으로 바로)
   * @param {*} provider provider
   * @param {*} amount KLAY 송금양
   */
async function buy (provider, amount) {
  const web3 = new Web3(provider)

  try {
    if (amount === '') {
      // error 발생
      alert('수량을 입력해주세요.')
      return
    }

    if (web3 !== undefined) {
      const accounts = await web3.eth.getAccounts()

      // 내 주소
      const myAddress = accounts[0]
      // 보낼 metic 양
      const tokenOwnerValue = web3.utils.toHex(web3.utils.toWei(amount.toString(), 'ether'))

      // @ts-ignore
      const contractInstance = new web3.eth.Contract(kdiaCrowdSale, crowdsaleAddress, {
        from: myAddress
      })

      // 컨트랙트에 보낼 데이터
      const tx = {
        from: myAddress,
        to: crowdsaleAddress,
        value: tokenOwnerValue,
        data: contractInstance.methods.invest().encodeABI()
      }

      web3.eth.sendTransaction(tx, async function (error, hash) {
        if (!(error)) {
          // a
          alert(`구매 성공했습니다.\n${hash}`)
        } else {
        }
      })
    }
  } catch (err) {
    // console.log(err)
    return false
  }
}

export default {
  connect, buy
}
