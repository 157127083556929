<template>
  <div :style="{backgroundColor: '#FAFAFA', display: 'flex', alignItems: 'end', justifyContent: 'end'}">
    <div ref="company_img" :style="{position: 'relative', width: '100%', height: '30vw', minHeight: '135px', backgroundImage: 'url(' + image + ')', backgroundSize: 'contain', backgroundRepeat: 'no-repeat' }">
      <div :style="{display: 'flex', flexDirection: 'column',position: 'absolute', right: '4%', bottom: '7%', textAlign: 'end'}">
      <span :style="{ color: '#000', fontWeight: 400, fontSize: '1vw'}">
              Telegram / KakaoTalk / Instagram<br />Twitter / Youtube
                </span>
              </div>
      <div :style="{display:'flex', position: 'relative', alignItems: 'end', justifyContent: 'end', height: '100%', width: 'calc(25% + 20vw)', backgroundColor: '#032D98'}">
        <div :style="{display: 'flex', flexDirection: 'column',position: 'absolute', left: 'calc(2.5% + 10vw)', top: '50%', transform: 'translate(0, -50%)', paddingRight: '15%'}">
        <span :style="{ fontWeight: 700, fontSize: '2vw', color: '#fff' }">TIPS</span>
        <div :style="{ width: '30%', backgroundColor: '#FFF', height: '2px', margin: '5px 0', padding: 0 }" />
        <span :style="{ fontWeight: 700, fontSize: '1vw', color: '#fff' }">TIPS 플랫폼에서 자유롭게 글과 이미지를 올리세요. 당신이 게시판에 글을 적고 정보와 이야기를함께하면 TIPS 코인이 항상 무료 배포되어집니다.</span>
      </div>
    </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'Company',
  components: {
  },
  mounted () {
  },
  methods: {
  },
  data () {
    return {
      imageH: 0,
      clientHeight: 0,
      image: require('../../assets/company.png')
    }
  },
  watch: {
    // clientHeight (newVal) {
    //   console.log(newVal)
    //   this.imageH = newVal
    // }
  }

}
</script>
