<template>
  <div>
    <b-modal id="warningModal"
             v-model="modalShow"
             footer-class="justify-content-center"
             size="md"
             no-close-on-backdrop
             no-close-on-esc
             centered
             scrollable>

      <template #modal-header="{ close }">
        <h5 class="modal-title">서버 이전 안내</h5>
        <button type="button" class="close" @click="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </template>

      <template>
          <p>
            현재 서버 폭주 및 사용량 과다로 인한 서버 이전 작업이 4월 17일까지 진행중입니다.
            해당 시간동안 사용에 불편함이 있을수 있습니다. 최대한 빠른 작업을 통해서 회원님들의 불편함을 최소화하겠습니다.
        </p>
        <p :style="{textAlign: 'end'}">
          - TIPS FOUNDATION 운영팀 -
        </p>
      </template>
      <template #modal-footer="{ ok }">
        <div :style="{display: 'flex', justifyContent: 'space-between', width: '100%'}">
          <div :style="{display: 'flex', gap: '10px',justifyContent: 'center', alignItems: 'center'}">
        <input type="checkbox" :checked="checked" @change="() => checked = !checked">
        오늘 하루 그만보기
      </div>
        <b-button size="sm" variant="success" @click="() => closeModal(ok)">확인</b-button>
      </div>
      </template>

    </b-modal>
  </div>
</template>

<script>

import cookie from 'js-cookie'

export default {
  name: 'warningModal',
  props: [
    'value'
  ],
  data () {
    return {
      modalShow: this.value,
      matic: '',
      polyt: '0',
      address: '',
      reward: this.total_reward,
      checked: false
    }
  },
  methods: {
    closeModal (ok) {
      if (!this.checked) {
        ok()
        return
      }
      this.setCookie('bPop', 'Y', 1)
      ok()
    },
    setCookie (key, value, expireDays) {
      cookie.set(key, value, {
        expires: expireDays,
        path: ''
      })
    }
  },
  watch: {
    value (newVal) {
      this.modalShow = newVal
    }
  }
}
</script>
