import { render, staticRenderFns } from "./Whitepaper.vue?vue&type=template&id=70ad2d01&"
import script from "./Whitepaper.vue?vue&type=script&lang=js&"
export * from "./Whitepaper.vue?vue&type=script&lang=js&"
import style0 from "./Whitepaper.vue?vue&type=style&index=0&id=70ad2d01&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports