<template>
    <div class="rank-box" :style="{ flexDirection: 'column', padding: 0, alignItems: 'start', justifyContent: 'center' }">
        <!-- <h5 :style="{ paddingLeft: '5%' }">채굴 순위</h5> -->
        <div :style="{padding: '0 5%', display: 'flex', justifyContent: 'center', alignItems: 'center'}">
          <span :style="{ padding: '10px 5px', fontSize: '1rem', fontWeight: '600', color: '#000' }">채굴 순위</span>
        <div>
    <b-button id="tooltip-button-interactive1" variant="none"><h5><i class="ri-question-line"></i></h5></b-button>
    <b-tooltip target="tooltip-button-interactive1">포스팅하여 채굴한 TIP 순위</b-tooltip>
  </div>
      </div>
        <div :style="{ border: '1px solid #acacac', width: '100%', padding: '10px' }">
            <div
                :style="{ display: 'flex', flexDirection: 'row', width: '100%', height: 'auto' }">
                <div :style="{width: '50%'}" class="rank-box-gap">
                    <div v-for="(item, index) in items1" :key="index" :style="{  width: '100%' }">
                        <div :style="{ display: 'flex', flexDirection: 'row', flex: 1 }">
                            <div
                                :style="{ width: '30%', height: '3vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }">
                                <img v-show="index === 0" :src=image
                                    :style="{ filter: 'opacity(0.5) drop-shadow(0 0 0 #FFD700)', transform: 'scale(0.03)' }" />
                                <img v-show="index === 1" :src=image
                                    :style="{ filter: 'opacity(0.5) drop-shadow(0 0 0 #C0C0C0)', transform: 'scale(0.03)' }" />
                                <img v-show="index === 2" :src=image
                                    :style="{ filter: 'opacity(0.5) drop-shadow(0 0 0 #CD7F32)', transform: 'scale(0.03)' }" />
                                <span v-show="index > 2">No.{{ index }}</span>
                            </div>
                            <div :style="{width: '100%', alignItems: 'center', display: 'flex'}">
                              <div :style="{ display: 'block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', flex: 1 }">{{ item.name }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div :style="{width: '50%'}" class="rank-box-gap">
                    <div v-for="(item, index) in items2" :key="index" :style="{ width: '100%' }">
                        <div :style="{ display: 'flex', flexDirection: 'row' }">
                            <div
                                :style="{ width: '30%', height: '3vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }">
                                <span >No.{{ index + 5 }}</span>
                            </div>
                            <div :style="{width: '100%', alignItems: 'center', display: 'flex'}">
                              <div :style="{ display: 'block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', flex: 1 }"> {{ item.name }} </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import setting from '../../services/setting'

export default {
  name: 'Rank',
  components: {
  },
  mounted () {
    this.interval()
    this.getRank()
  },
  methods: {
    async interval () {
      this.auto_reload_func = setInterval(async () => {
        const res = await setting.getRank()
        this.items1 = res.data.user.slice(0, 5)
        this.items2 = res.data.user.slice(5, 10)
      }, 60 * 1000)
    },
    async getRank () {
      const res = await setting.getRank()
      this.items1 = res.data.user.slice(0, 5)
      this.items2 = res.data.user.slice(5, 10)
    }
  },
  destroyed () {
    clearInterval(this.auto_reload_func)
  },
  data () {
    return {
      auto_reload_func: null,
      items1: [],
      items2: [],
      image: require('../../assets/rank.png')
    }
  }
}
</script>
<style>
.rank-box {
    display: flex;
    width: 48%;
}
@media (max-width: 480px) {
    .rank-box {
    display: flex;
    width: 100%;
    }
    .rank-box-gap {
        gap: 10px;
        display: flex;
        flex-direction: column;
    }
}
</style>
