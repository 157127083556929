<template>
  <div :style="{ width: '100%' }">
    <WarningModal :value="modalShow" />
    <div :style="{ display: 'flex', alignItems: 'center', justifyContent: 'center' }">
      <iq-card
        :style="{ marginBottom: '0px', width: '100%', alignItems: 'center', justifyContent: 'center', background: '#032D98', borderRadius: '0px' }">
        <div
          :style="{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px 0' }">
          <span :style="{ fontWeight: 500, fontSize: '1rem', color: '#fff' }">
            금일 누적 방문자수:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <span :style="{ fontWeight: 700, fontSize: '1rem', color: '#fff' }">
              {{ (7 * +totalVisit).toLocaleString() }}명
            </span>
          </span>
        </div>
      </iq-card>
    </div>

    <div :style="{width: '100%'}">
      <div class="myLang" id='google_translate_element'></div>
    </div>

    <SetWidth color="#FFF" padding="30px 0">
      <div class="share-box">
        <Rank />
        <Level />
      </div>
    </SetWidth>
    <div :style="{ width: '100%', backgroundColor: '#FAFAFA', height: '5px', margin: '10px 0', padding: 0 }" />
    <SetWidth color="#FFF" padding="30px 0">
      <ListForm color="#FFF" :title="latest" :items="latest_items" :path="latest_path" :isBusy="latestBusy"
        :fieldsType="fieldsType" :total="latest_total" :perPage="5" />
    </SetWidth>
    <div :style="{ width: '100%', backgroundColor: '#FAFAFA', height: '5px', margin: '10px 0', padding: 0 }" />
    <SetWidth color="#FFF" padding="30px 0">
      <ListForm color="#FFF" :title="popular" :items="popular_items" :isBusy="popularBusy" :fieldsType="fieldsType"
        :total="popular_total" :perPage="5" />
    </SetWidth>
    <div :style="{ width: '100%', backgroundColor: '#FAFAFA', height: '5px', margin: '10px 0', padding: 0 }" />
    <SetWidth color="#FFF">
      <Whitepaper id="whitepaper" ref="whitepaper"  />
    </SetWidth>
    <div :style="{ width: '100%', backgroundColor: '#FAFAFA', height: '5px', margin: '10px 0', padding: 0 }" />
    <SetWidth color="#FFF">
      <Coin id="coin" ref="coin" />
    </SetWidth>
    <div :style="{ width: '100%', backgroundColor: '#FAFAFA', height: '5px', margin: '10px 0', padding: 0 }" />
    <SetWidth color="#FFF">
      <Info />
    </SetWidth>
    <div :style="{ width: '100%', backgroundColor: '#FAFAFA', height: '5px', margin: '10px 0', padding: 0 }" />
    <SetWidth color="#FFF">
      <Faq />
    </SetWidth>
    <div :style="{ width: '100%', backgroundColor: '#FAFAFA', height: '5px', margin: '10px 0', padding: 0 }" />
    <Company id="company" ref="company" />
  </div>
</template>

<script>

import { core } from '../../config/pluginInit'
import ListForm from '../Dashboard/Form/ListForm.vue'
import Coin from './Coin.vue'
import Info from './Info.vue'
import Faq from './Faq.vue'
import posted from '../../services/dashboard/posted'
import SetWidth from '../../components/setWidth.vue'
import Company from './Company.vue'
import WarningModal from '../../components/core/modal/warningModal.vue'
import setting from '../../services/setting'
import Rank from './Rank.vue'
import Level from './Level.vue'
import Whitepaper from './Whitepaper.vue'

import cookie from 'js-cookie'

export default {
  name: 'Home',
  components: {
    ListForm,
    SetWidth,
    Coin,
    Info,
    Faq,
    Company,
    WarningModal,
    Rank,
    Level,
    Whitepaper
  },
  mounted () {
    const recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute(
      'src',
      'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
    )
    document.head.appendChild(recaptchaScript)

    core.index()
    this.today()
    this.getLatestList()
    this.getPopularList()
    // this.checkCookie('bPop')
  },
  methods: {
    today () {
      setting.visit().then((res) => {
        if (res.data.resultCode === 'false') {
          return
        }
        this.totalVisit = res.data.count ?? 0
      })
    },
    getLatestList () {
      posted.getLatestList(5).then(res => {
        if (res.status !== 200) {
          return alert('서버 오류')
        }
        if (res.data.resultCode === 'false') {
          alert('서버 오류')
          this.setLatestBusy()
          return
        }
        this.setLatestBusy()
        if (res.data.community.length > 0) {
          this.fieldsType = 'list'
          this.latest_items = res.data.community
          this.total = res.data.total
        } else {
          this.fieldsType = 'span'
          this.latest_items = [{ pk: '', title: '작성된 글이 없습니다.' }]
        }
      })
    },
    getPopularList () {
      posted.getPopularList(5).then(res => {
        if (res.status !== 200) {
          return alert('서버 오류')
        }
        if (res.data.resultCode === 'false') {
          alert('서버 오류')
          this.setPopularBusy()
          return
        }
        this.setPopularBusy()
        if (res.data.community.length > 0) {
          this.fieldsType = 'list'
          this.popular_items = res.data.community
          this.total = res.data.total
        } else {
          this.fieldsType = 'span'
          this.popular_items = [{ pk: '', title: '작성된 글이 없습니다.' }]
        }
      })
    },
    setLatestBusy () {
      this.latestBusy = !this.latestBusy
    },
    setPopularBusy () {
      this.popularBusy = !this.popularBusy
    },
    checkCookie (name) {
      this.modalShow = false
      if (cookie.get(name) === undefined) {
        this.modalShow = true
      } else {
        this.modalShow = false
      }
    }
    // googleTranslateElementInit () {
    //   new google.translate.TranslateElement({
    //     pageLanguage: 'ko',
    //     layout: google.translate.TranslateElement.InlineLayout.HORIZONTAL,
    //     autoDisplay: true
    //   }, 'google_translate_element')
    // }
  },
  data () {
    return {
      isLogin: false,
      latest: '최근 게시글',
      latest_path: 'latest',
      popular: '인기 게시글',
      popular_path: 'popular',
      latestBusy: true,
      popularBusy: true,
      latest_items: [],
      popular_items: [],
      latest_total: 0,
      popular_total: 0,
      fieldsType: '',
      modalShow: false,
      totalVisit: 0
    }
  }

}
</script>
<style scoped>
.progress-bar-info {
  background-color: #0cd2e3 !important;
}

.progress-bar-success {
  background-color: #1be1b3 !important;
}

.progress-bar-warn {
  background-color: #f4b72a !important;
}

.progress-bar-pink {
  background-color: #fd6c9e !important;
}

.share-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.myLang{
  display: flex;
  justify-content: end;
}

@media (max-width: 480px) {
  .myLang{
  display: block;
}

  .share-box {
    flex-direction: column;
    gap: 30px;
  }
}
</style>
